import './AdvancedConfigurations.less';

import {
  ClockCircleOutlined,
  MinusCircleOutlined,
  QuestionCircleOutlined,
  RetweetOutlined,
} from '@ant-design/icons';
import { Button, Menu, Spin, Tabs, Tag } from 'antd';
import React, { useEffect, useReducer } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { TabConfiguration } from './components/TabConfiguration';
import {
  AdvanceConfigurationState,
  AdvancedConfigurationReducerAction,
  getAdvancedConfigCategoryGuildUrl,
  mapChangesToUpdateAdvancedConfigurationsRequest,
  normalizeAdvancedConfigurations,
} from './utils/utils';
import { getClusterStatus } from 'utils/cluster';
import { getErrorMessage, showErrorMessage } from 'utils/errors';
import { displaySuccessMessage } from 'utils';
import {
  getAdvancedConfigurations,
  updateAdvancedConfigurations,
} from 'services/cluster';
import {
  AdvancedConfig,
  AdvancedConfigTabDisplayName,
  ConfigCategoryType,
  Instance,
  Service,
} from 'types/cluster';

function advancedConfigurationReducer(
  state: AdvanceConfigurationState,
  { type, payload }: AdvancedConfigurationReducerAction,
): AdvanceConfigurationState {
  const {
    requiresRestart,
    changes,
    selectedConfigs,
    currentSelectedTab,
    normalizedAdvancedConfigs,
  } = state;

  switch (type) {
    case 'CONIFGURATIONS_FETCHED_SUCCEEDED': {
      const normalizedAdvancedConfigs = normalizeAdvancedConfigurations(
        payload!.advancedConfigs!,
      );
      const selectedConfigsTmp = selectedConfigs
        ? selectedConfigs
        : (payload!.advancedConfigs! as AdvancedConfig[]).reduce(
            (acc: Record<string, ConfigCategoryType>, advancedConfig) => {
              acc[advancedConfig.displayName] =
                advancedConfig.categories[0].category;
              return acc;
            },
            {},
          );
      const currentSelectedTabTmp = currentSelectedTab
        ? currentSelectedTab
        : payload!.advancedConfigs![0].displayName;
      const helpUrl = getAdvancedConfigCategoryGuildUrl(
        normalizedAdvancedConfigs[currentSelectedTabTmp].id,
        selectedConfigsTmp[currentSelectedTabTmp],
      );
      const servicesRequireRestart: {
        name: 'loader' | 'analytics' | 'all' | 'SQLi';
        color: 'green' | 'gold' | 'purple' | 'blue';
      }[] = [
        {
          name: 'loader',
          color: 'green',
        },
        {
          name: 'analytics',
          color: 'gold',
        },
        ...(payload!.isSqliSeparated!
          ? [
              {
                name: 'SQLi',
                color: 'blue',
              } as const,
            ]
          : []),
        {
          name: 'all',
          color: 'purple',
        },
      ];
      return {
        ...state,
        changes: changes ? changes : undefined,
        advancedConfigs: payload!.advancedConfigs!,
        normalizedAdvancedConfigs,
        selectedConfigs: selectedConfigsTmp,
        currentSelectedTab: currentSelectedTabTmp,
        helpUrl,
        servicesRequireRestart,
        isAdvancedConfigsEditable: payload!.isAdvancedConfigsEditable!,
      };
    }
    case 'SAVING_CONFIGURATION_CHANGES_SUCCEEDED': {
      return {
        ...state,
        isSaveDisabled: true,
        requiresRestart: false,
      };
    }
    case 'CATEGORY_CONFIG_UPDATED': {
      const currentSelectedCateogryType = selectedConfigs![currentSelectedTab!];
      const restart = {
        ...(requiresRestart === false &&
          payload!.restartRequired === true && { requiresRestart: true }),
      };
      const hasChanges = !!changes;
      const hasCurrentTabChanges =
        hasChanges && !!changes![currentSelectedTab!];
      const hasCurrentCategoryChanges =
        hasCurrentTabChanges &&
        !!changes![currentSelectedTab!][currentSelectedCateogryType];

      const prevChanges = hasChanges ? changes : {};
      const prevCurrentTabChanges = hasCurrentTabChanges
        ? changes![currentSelectedTab!]
        : {};
      const prevCurrentCategoryChanges = hasCurrentCategoryChanges
        ? changes![currentSelectedTab!][currentSelectedCateogryType]
        : {};

      const updatedChanges = {
        ...prevChanges,
        [currentSelectedTab!]: {
          ...prevCurrentTabChanges,
          [currentSelectedCateogryType]: {
            ...prevCurrentCategoryChanges,
            [payload!.CategoryConfigItemKey!]:
              payload!.CategoryConfigItemValue!,
            id: normalizedAdvancedConfigs![currentSelectedTab!].id,
          },
        },
      };

      return {
        ...state,
        ...restart,
        changes: updatedChanges!,
        isSaveDisabled: false,
      };
    }
    case 'CONFIGURATION_CATEGORY_SELECTION_CHANGED': {
      return {
        ...state,
        selectedConfigs: {
          ...selectedConfigs,
          [currentSelectedTab!]: payload!.categorySelection!,
        },
        helpUrl: getAdvancedConfigCategoryGuildUrl(
          normalizedAdvancedConfigs![currentSelectedTab!].id,
          payload!.categorySelection!,
        ),
      };
    }
    case 'CONFIGURATION_TAB_SELECTION_CHANGED': {
      return {
        ...state,
        currentSelectedTab: payload!.tabSelection!,
        helpUrl: getAdvancedConfigCategoryGuildUrl(
          normalizedAdvancedConfigs![currentSelectedTab!].id,
          selectedConfigs![payload!.tabSelection!],
        ),
      };
    }
    default:
      return { ...state };
  }
}

const initialState: AdvanceConfigurationState = {
  changes: undefined,
  advancedConfigs: [],
  normalizedAdvancedConfigs: undefined,
  isSaveDisabled: true,
  requiresRestart: false,
  selectedConfigs: undefined,
  currentSelectedTab: undefined,
  helpUrl: '',
  servicesRequireRestart: [],
  isAdvancedConfigsEditable: false,
};

const ADVANCED_CONFIGURATION_QUERY_ID = 'advanced-configurations';

export function AdvancedConfigurations({
  instance,
  services,
}: {
  instance: Instance;
  services: Service[];
}) {
  const { state: linkState = {} } = useLocation<any>() ?? {};
  const isIncortaLabs = linkState?.linkTo === 'incorta-labs';

  const [state, dispatch] = useReducer(
    advancedConfigurationReducer,
    initialState,
  );
  const clusterStatus = getClusterStatus(instance, services[0]);
  const isClusterSleeping = clusterStatus === 'sleeping';
  const isClusterRunning = clusterStatus === 'running';
  const isClusterStarting = !isClusterRunning && !isClusterSleeping;

  const {
    isSaveDisabled,
    advancedConfigs,
    selectedConfigs,
    currentSelectedTab,
    normalizedAdvancedConfigs,
    changes,
    helpUrl,
    requiresRestart,
    servicesRequireRestart,
    isAdvancedConfigsEditable,
  } = state;

  useEffect(() => {
    if (isIncortaLabs && normalizedAdvancedConfigs) {
      setTimeout(() => {
        handleTabSelectionChange('Server Configurations');
        handleCategorySelectionChange('Incorta Labs');
        requestAnimationFrame(() => {
          document
            .querySelector('[data-testid="systemconfiguration-menu"]')
            ?.scrollTo({ top: 300, behavior: 'smooth' });
        });
      }, 200);
    }
  }, [isIncortaLabs, normalizedAdvancedConfigs]);

  const queryClient = useQueryClient();

  const {
    isLoading: fetchingAdvancedConfigs,
    isFetching: synchingAdvancedConfigs,
  } = useQuery(
    [ADVANCED_CONFIGURATION_QUERY_ID, instance.id],
    () => getAdvancedConfigurations(instance.id),
    {
      enabled: isClusterRunning,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      // refetchOnMount: false,
      onError: error => showErrorMessage(getErrorMessage(error)),
      onSuccess: data =>
        dispatch({
          type: 'CONIFGURATIONS_FETCHED_SUCCEEDED',
          payload: {
            advancedConfigs: data,
            isSqliMigrated: instance.isSqliMigrated,
            isSqliSeparated: instance.isSqliSeparated,
            isAdvancedConfigsEditable: ['owner', 'accountAdmin'].includes(
              instance.clusterPolicy.roleName!,
            ),
          },
        }),
    },
  );

  const { isLoading: savingConfigurationChanges, mutate: handleSubmit } =
    useMutation(
      () =>
        updateAdvancedConfigurations(
          instance.id,
          mapChangesToUpdateAdvancedConfigurationsRequest(changes!),
        ),
      {
        onError: error => showErrorMessage(getErrorMessage(error)),
        onSuccess: () => {
          queryClient.invalidateQueries([
            ADVANCED_CONFIGURATION_QUERY_ID,
            instance.id,
          ]);

          dispatch({ type: 'SAVING_CONFIGURATION_CHANGES_SUCCEEDED' });

          displaySuccessMessage(
            `Advanced Configurations has been updated successfully${
              requiresRestart ? '. Service(s) must be restarted' : ''
            }`,
          );
        },
      },
    );

  const handleCategorySelectionChange = (category: ConfigCategoryType) => {
    dispatch({
      type: 'CONFIGURATION_CATEGORY_SELECTION_CHANGED',
      payload: { categorySelection: category },
    });
  };

  const handleConfigurationValueChange = (
    CategoryConfigItemKey: string,
    CategoryConfigItemValue: string | number | boolean,
    restartRequired: boolean,
  ) => {
    dispatch({
      type: 'CATEGORY_CONFIG_UPDATED',
      payload: {
        CategoryConfigItemKey,
        CategoryConfigItemValue,
        restartRequired,
      },
    });
  };

  const handleTabSelectionChange = (tab: AdvancedConfigTabDisplayName) => {
    dispatch({
      type: 'CONFIGURATION_TAB_SELECTION_CHANGED',
      payload: {
        tabSelection: tab,
      },
    });
  };

  return (
    <div
      className="inc-systemconfiguration"
      data-testid="inc-systemconfiguration"
    >
      {isClusterSleeping && (
        <div>
          <MinusCircleOutlined style={{ fontSize: '30px', color: '#C9D3D7' }} />
          <div style={{ fontSize: '16px', color: '#393E41' }}>
            Cluster is disconnected
          </div>
          <div style={{ color: '#979797' }}>
            Your cluster must be connected to view advanced configurations
          </div>
        </div>
      )}
      {isClusterStarting && (
        <div>
          <ClockCircleOutlined style={{ fontSize: '30px', color: '#C9D3D7' }} />
          <div style={{ fontSize: '16px', color: '#393E41' }}>
            Cluster is loading...
          </div>
          <div style={{ color: '#979797' }}>
            Your cluster must be connected to view advanced configurations
          </div>
        </div>
      )}
      {isClusterRunning && fetchingAdvancedConfigs && (
        <Spin spinning={true}></Spin>
      )}
      {isClusterRunning &&
        !fetchingAdvancedConfigs &&
        !!advancedConfigs.length && (
          <>
            <div className="systemconfiguration-tabs">
              <Tabs
                defaultActiveKey={currentSelectedTab}
                animated={false}
                onChange={tab =>
                  handleTabSelectionChange(tab as AdvancedConfigTabDisplayName)
                }
                tabBarExtraContent={
                  <a
                    className="help-link"
                    href={helpUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Help"
                  >
                    <Button
                      type="link"
                      icon={
                        <QuestionCircleOutlined style={{ fontSize: '16px' }} />
                      }
                    >
                      Help
                    </Button>
                  </a>
                }
              >
                {advancedConfigs?.map(config => (
                  <Tabs.TabPane
                    tab={config.displayName}
                    key={config.displayName}
                  >
                    <div className="systemconfiguration-main">
                      {
                        <>
                          <span
                            className="systemconfiguration-menu"
                            data-testid="systemconfiguration-menu"
                          >
                            <Menu
                              id="incorta-configurations-menu"
                              onClick={({ key }) => {
                                handleCategorySelectionChange(
                                  key as ConfigCategoryType,
                                );
                              }}
                              selectedKeys={[
                                selectedConfigs![config.displayName!],
                              ]}
                              defaultSelectedKeys={[
                                selectedConfigs![config.displayName!],
                              ]}
                              mode="inline"
                            >
                              {normalizedAdvancedConfigs![
                                config.displayName!
                              ].categoriesList.map(({ category }: any) => (
                                <Menu.Item key={category}>{category}</Menu.Item>
                              ))}
                            </Menu>
                          </span>
                          <span
                            className="systemconfiguration-settings"
                            id={config.displayName}
                          >
                            <TabConfiguration
                              tab={config.displayName}
                              changes={changes!}
                              selectedCategory={
                                selectedConfigs![config.displayName!]
                              }
                              categoryConfigs={
                                normalizedAdvancedConfigs![config.displayName!]
                                  .categories[
                                  selectedConfigs![config.displayName!]
                                ]
                              }
                              isAdvancedConfigsEditable={
                                isAdvancedConfigsEditable
                              }
                              loading={synchingAdvancedConfigs}
                              handleConfigurationValueChange={
                                handleConfigurationValueChange
                              }
                            />
                          </span>
                        </>
                      }
                    </div>
                  </Tabs.TabPane>
                ))}
              </Tabs>
            </div>
            {!fetchingAdvancedConfigs && (
              <div className="systemconfiguration-footer">
                <span className="require-server-restart">
                  {servicesRequireRestart.map(item => (
                    <span className="server-restart" key={item.name}>
                      <Tag color={item.color}>
                        <RetweetOutlined /> {item.name}
                      </Tag>
                      <span>
                        Requires <b>{item.name}</b> service
                        {item.name === 'all' && 's'} restart
                      </span>
                    </span>
                  ))}
                </span>
                <Button
                  type="primary"
                  className="save-button"
                  disabled={isSaveDisabled}
                  onClick={() => handleSubmit()}
                  loading={savingConfigurationChanges}
                >
                  Save
                </Button>
              </div>
            )}
          </>
        )}
    </div>
  );
}
