import './ClusterEnableIncortaXEdit.less';

import Icon, { InfoCircleOutlined } from '@ant-design/icons';
import { Modal, notification, Switch, Tooltip } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import CopyableText from 'components/CopyableText/CopyableText';
import { useMutateIncortaXState } from 'hooks/cluster';
import useToggleFeature, { TOGGLE_FEATURE_KEYS } from 'hooks/useToggleFeature';
import { ReactComponent as BiTools } from 'images/bi-tools-icon.svg';
import {
  Instance,
  SelfManagedClusterTypes,
  ServerConfiguration,
  Service,
} from 'types/cluster';
import { UserData } from 'types/user';
import { getClusterStatus } from 'utils/cluster';

interface ClusterEnableIncortaXEditProps {
  instance: Instance;
  incortaXServerConfiguration: ServerConfiguration[];
  services: Service[];
  user: UserData;
}

function ClusterEnableIncortaXEdit({
  instance,
  incortaXServerConfiguration,
  services,
  user,
}: ClusterEnableIncortaXEditProps) {
  let { mutateAsync: mutateIncortaXState, isLoading } = useMutateIncortaXState({
    clusterName: instance.name,
  });

  const { isToggleFeatureEnabled: isIncortaXFeatureEnabled } = useToggleFeature(
    {
      user,
      featureKey: TOGGLE_FEATURE_KEYS.incortaX,
    },
  );

  const isEnabledFromSelfManagedCluster =
    instance.incortaXEnabled &&
    !!instance.subClusters.find(
      subCluster => subCluster.type === SelfManagedClusterTypes.sqlx,
    );

  async function handleChange(enable: boolean) {
    if (enable) {
      Modal.confirm({
        title: 'Enable Advanced SQL Interface',
        content: instance?.sqliNullHandling ? (
          <>
            Enabling Advanced SQL Interface will restart Incorta Services, are
            you sure you want to proceed?
            <br />
            For consistent query results, it is strongly advisable to enable{' '}
            <b>Null Handling</b>.
          </>
        ) : (
          'Enabling Advanced SQL Interface will restart Incorta Services, are you sure you want to proceed?'
        ),
        okText: 'Proceed',
        centered: true,
        width: 620,
        async onOk() {
          let { data } = await mutateIncortaXState(true);
          notification.success({
            message: 'Success',
            description: data.message,
          });
        },
      });
    } else {
      Modal.confirm({
        title: 'Disable Advanced SQL Interface',
        content:
          'Disabling will lead to loss of any current or upcoming query requests.',
        okText: 'Disconnect',
        centered: true,
        width: 620,
        async onOk() {
          let { data } = await mutateIncortaXState(false);
          notification.success({
            message: 'Success',
            description: data.message,
          });
        },
      });
    }
  }

  let canUpdate = instance.clusterPolicy.clusterPolicy.includes('update');
  let status = getClusterStatus(instance, services[0]);

  let enable = status === 'running';
  return isIncortaXFeatureEnabled ? (
    <div className="ClusterEdit ClusterEnableIncortaXEdit">
      <div className="ClusterEdit__icon">
        <Icon component={BiTools} />
      </div>
      <div className="ClusterEdit__content ClusterEnableIncortaXEdit__content">
        <div className="ClusterEnableIncortaXEdit__main">
          <h3 className="ClusterEdit__title">Enable Advanced SQL Interface</h3>
          <div className="ClusterEnableIncortaXEdit__body">
            <p className="ClusterEnableIncortaXEdit__hint">
              <FormattedMessage id="clusterConfiguration.incortaX.mainHint" />
              <label className="ClusterEnableIncortaXEdit__hint-more">
                {instance?.sqliNullHandling ? (
                  <FormattedMessage
                    id="queryResultsMessage"
                    defaultMessage={`For consistent and accurate query results, it is highly recommended to enable this feature along with the {link} option. Disabling <b>Null Handling</b> while using the Advanced SQL Interface may lead to inconsistent query outcomes. <b>Toggling this configuration will restart all services.</b>`}
                    values={{
                      link: (
                        <b>
                          <Link
                            to={{
                              pathname: `/clusters/${instance?.name}`,
                              search: `tab=advanced-configurations`,
                              state: { linkTo: 'incorta-labs' },
                            }}
                          >
                            Null Handling
                          </Link>
                        </b>
                      ),
                      b: (chunks: React.ReactNode) => <b>{chunks}</b>,
                    }}
                  />
                ) : (
                  <FormattedMessage id="clusterConfiguration.incortaX.moreHint" />
                )}
              </label>
            </p>

            {instance.incortaXEnabled ? (
              <div className="ClusterEnableIncortaXEdit__info-wrapper">
                {incortaXServerConfiguration.map(config => {
                  return (
                    <div
                      key={config.name}
                      className="ClusterEnableIncortaXEdit__info-box"
                    >
                      <div className="ClusterEnableIncortaXEdit__info-box-label">
                        {config.name}{' '}
                        <Tooltip
                          placement="top"
                          title={config.description}
                          overlayClassName="full-width-tooltip"
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      </div>
                      <div className="ClusterEnableIncortaXEdit__info-box-content">
                        <CopyableText text={config.text} />
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
        <div className="ClusterEnableIncortaXEdit__switch-button">
          {canUpdate && isIncortaXFeatureEnabled && (
            <Tooltip
              title={
                status !== 'running' ? (
                  'Your cluster must be connected to apply this change'
                ) : instance.platform !== 'gc' ? (
                  'Advanced SQL Interface is Currently Unavailable for Azure Clusters!'
                ) : !instance.isIncortaXEligible ? (
                  'Unlock Advanced SQL Interface: Upgrade your cluster today for access. Enhance your data capabilities now!'
                ) : isEnabledFromSelfManagedCluster ? (
                  <>
                    You can disable it by removing the
                    <Link
                      to={`/clusters/${instance.name}?tab=self-managed-clusters`}
                    >
                      {` SQLX self-managed cluster`}
                    </Link>
                  </>
                ) : (
                  ''
                )
              }
            >
              <Switch
                onChange={handleChange}
                loading={isLoading}
                checked={instance.incortaXEnabled}
                disabled={
                  !enable ||
                  !isIncortaXFeatureEnabled ||
                  !instance.isIncortaXEligible ||
                  instance.platform !== 'gc' ||
                  isEnabledFromSelfManagedCluster
                }
              />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  ) : null;
}

export default ClusterEnableIncortaXEdit;
